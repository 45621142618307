/*
 * @Descripttion: 员工管理相关接口
 * @version: 1.3.0
 * @Author: wangjuan
 * @Date: 2023-03-10 16:26:54
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-29 10:12:12
 */
import { get, post } from '@/apis/method'

const getEmployeesLead = (data) => get('/api/employee/lead', data)
const getEmployeesInfo = (data) => get('/api/employee/info', data)
const updateEmployees = (data) => post('/api/employee/update', data) // 更新数据
const getEmployeesOptions = (data) => get('/adminv2/employee/options', data) // 获取下拉框数据

const exportEmployees = (data) => post('/api/employee/export', data) // 导出

export {
  getEmployeesLead,
  getEmployeesInfo,
  updateEmployees,
  getEmployeesOptions,
  exportEmployees
}
