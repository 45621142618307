/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-08-26 17:55:18
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-12-04 13:56:58
 */

/*
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-08-19 17:32:36
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-26 18:10:00
 */
import CsvExportor from 'csv-exportor'

/**
 * @description: 导出excel
 * @return {*}
 * @param {*} data 数据源
 */
export const exportExcel = (data, enu) => {
  const table = []
  // 需要展示的字段
  const showLists = [...basicData, ...deptData, ...jobData, ...timeData]
  // 根据表头拼装导出数据格式
  data.forEach(item => {
    console.log(item)
    const obj = {}
    showLists.forEach(({ prop, type }) => {
      if (type === 'select') {
        obj[prop] = enu[prop][item[prop]]
      } else if (prop === 'dept') {
        obj[prop] = Object.values(item[prop]).join(';')
      } else if (prop.includes('_date') || prop === 'mobile') {
        obj[prop] = `${item[prop]}\t`
      } else {
        obj[prop] = item[prop] || '--'
      }
    })
    table.push(obj)
  })

  // 获取时间戳
  const timestamp = new Date().getTime()
  // 导出
  CsvExportor.downloadCsv(table, { header: showLists.map(item => item.label) }, `部门档案_${timestamp}.csv`)
}
// 基础信息数据
export const basicData = [{
  prop: 'job_number',
  label: '工号'
},
{
  prop: 'name',
  label: '姓名'
},
{
  prop: 'is_leave',
  label: '状态',
  type: 'select',
  map: {
    0: '在职',
    1: '离职'
  }
},
{
  prop: 'sex',
  label: '性别',
  type: 'select',
  map: {
    0: '在职',
    1: '男'
  }
},
{
  prop: 'age',
  label: '年龄'
},
{
  prop: 'employee_type',
  label: '员工类型'
},
{
  prop: 'recruitment_type',
  label: '招聘类型',
  type: 'select',
  map: {
    0: '在职',
    1: '男'
  }
},
{
  prop: 'company',
  label: '所属公司'
},
{
  prop: 'mobile',
  label: '联系电话'
},
{
  prop: 'interests_skills',
  label: '兴趣特长'
}
]

// 部门信息
export const deptData = [{
  prop: 'dept',
  label: '部门',
  className: 'dept-width'
},
{
  prop: 'direct_superior',
  label: '直属上级'
},
{
  prop: 'tutor',
  label: '导师'
}
]

// 岗位信息
export const jobData = [
  {
    prop: 'job',
    label: '岗位名称'
  },
  {
    prop: 'job_cate',
    label: '岗位类'
  },
  {
    prop: 'position',
    label: '职位名称'
  },
  {
    prop: 'career_sequence',
    label: '职业序列'
  },
  {
    prop: 'rank_post',
    label: '职级（职位）'
  },
  {
    prop: 'rank_professional',
    label: '职级（专业）'
  },
  {
    prop: 'job_title',
    label: '职称'
  }
]

// 时间信息
export const timeData = [
  {
    label: '入职时间',
    prop: 'hired_date'
  },
  {
    label: '试用期时长',
    prop: 'probation_period',
    type: 'input'
  },
  {
    label: '司龄（月）',
    prop: 'months_with_company',
    type: 'input'
  },
  {
    label: '合同转正日期',
    prop: 'contract_conversion_date'
  },
  {
    label: '实际转正日期',
    prop: 'actual_conversion_date'
  },
  {
    label: '实习开始日期',
    prop: 'internship_start_date'
  },
  {
    label: '实习结束日期',
    prop: 'internship_end_date'
  },
  {
    label: '合同开始日期',
    prop: 'contract_start_date'
  },
  {
    label: '合同结束日期',
    prop: 'actual_contract_end_date'
  }
]

// 卡信息数据
export const cardData = [{
  prop: 'bank_card',
  label: '银行卡号'
},
{
  prop: 'bank',
  label: '所属银行'
},
{
  prop: 'social_security',
  label: '社保电脑号'
},
{
  prop: 'provident_fund',
  label: '公积金卡号'
}]

// 户籍信息
export const nativeData = [{
  prop: 'native_place',
  label: '籍贯'
},
{
  prop: 'domicile',
  label: '户口所在地'
},
{
  prop: 'nation',
  label: '民族'
},
{
  prop: 'id_card',
  label: '身份证号'
},
{
  prop: 'birth_date',
  label: '出生日期',
  type: 'date'
},
{
  prop: 'birth_month',
  label: '生日月份'
},
{
  prop: 'id_card_expired_time',
  label: '身份证到期时间',
  type: 'date'
}
]

// 年假病假数据
export const annualData = [{
  prop: 'continuous_social_security',
  label: '入司前连续社保'
},
{
  prop: 'accumulated_social_security',
  label: '入司前累计社保'
},
{
  prop: 'annual_leave_allowance',
  label: '年假额度',
  type: 'select'
},
{
  prop: 'annual_leave_cur_year',
  label: '本年度年假'
},
{
  prop: 'remain_annual_leave_last_year',
  label: '上年度剩余年假'
},
{
  prop: 'annual_leave_taken',
  label: '已休年假'
},
{
  prop: 'avl_annual_leave',
  label: '可享受年假'
}, {
  prop: 'sick_leave_allowance',
  label: '病假额度',
  type: 'select'
},
{
  prop: 'sick_leave_cur_year',
  label: '本年度病假'
},
{
  prop: 'sick_leave_taken',
  label: '已休病假'
},
{
  prop: 'avl_sick_leave',
  label: '可享受病假'
}]

// 加班调休数据
export const overtimeData = [{
  prop: 'overtime_cur_year',
  label: '  加班调休(本年度)'
},
{
  prop: 'overtime_remain_cur_year',
  label: '剩余加班调休 (本年度)'
},
{
  prop: 'overtime_remain_last_year',
  label: '剩余加班调休 (上年度)'
},
{
  prop: 'overtime_used',
  label: '已休加班调休'
}]
