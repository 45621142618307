<!--
 * @Author: wangjuan
 * @Date: 2023-03-03 14:38:13
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-29 11:04:03
 * @FilePath: \sns_web\src\views\layout\personalCenter\employees\index.vue
-->
<template>
  <div class="employees-content">
    <div class="team-card">
      <p @click="getPersonal" class="line">个人档案</p>
      <template v-if="is_lead">
        <p @click="is_dept=!is_dept" >
          <el-checkbox v-model="isCheckAll" :indeterminate="isIndeterminate" label="" @click.stop="() => {}"></el-checkbox>
          <span>部门档案</span>
        </p>
        <div class="scroll-bar" >
          <el-menu
            active-text-color="#7972F0"
            background-color="#fff"
            default-active="2"
            text-color="#333"
            :unique-opened="true"
            @select="selectItem"
            v-show="is_dept">
            <template v-if="teamData.children?.length">
              <AlignmentConetentItem v-for="(item, index) in teamData.children"
                :ref="el => { deptItemRef[index] = el }"
                :key="item.name"
                :item="item"
                :level="String(item.level)"
                :tabindex="String(index)"
                @selectMenu="selectMenu"
                @changeCheck="changeCheck"
                v-show="item.is_leader"
              ></AlignmentConetentItem>
            </template>
            <template v-if="teamData.user?.length">
              <el-menu-item v-for="user in teamData.user" :key="user" @click="clickItem(user)" v-show="teamData.is_leader" >
                <el-checkbox v-model="user.checked" label="" @change="changeCheck" @click.stop="() =>{}"></el-checkbox>
                <span class="menu-item">{{user.name}}</span>
              </el-menu-item>
            </template>
          </el-menu>
          <el-button type="primary" size="small" class="export-btn"
            @click="exportData"
            :disabled="!isIndeterminate && !isCheckAll">
            导出
          </el-button>
      </div>
      </template>
    </div>
    <div class="employees-detail">
      <el-form :model="messageForm" :inline="true" :rules="rules" label-width="110px" ref="messageRef">
        <h3>基础信息</h3>
        <template v-for="({prop,label, type = 'input'}) in basicData" :key="prop" >
          <el-form-item :label="label" :prop="prop">
            <template v-if="type === 'select'">
              <el-select v-model="messageForm[prop]" filterable :prop="prop" placeholder="请选择" disabled>
                <el-option v-for="(item,index) in enu[prop]" :key="index" :label="item" :value="Number(index)"></el-option>
              </el-select>
            </template>
            <template v-else>
              <el-input v-model="messageForm[prop]" placeholder="请填写" clearable disabled />
            </template>
          </el-form-item>
        </template>
        <h3>部门信息</h3>
        <template v-for="({prop,label, className = ''}) in deptData" :key="prop" >
          <el-form-item :label="label" :prop="prop" :class="className">
            <el-input v-model="messageForm[prop]" placeholder="请填写" clearable disabled />
          </el-form-item>
        </template>
        <h3>岗位信息</h3>
        <template v-for="({prop,label, type = 'input'}) in jobData" :key="prop" >
          <el-form-item :label="label" :prop="prop">
            <template v-if="type === 'select'">
              <el-select v-model="messageForm[prop]" filterable :prop="prop" placeholder="请选择" disabled>
                <el-option v-for="(item,index) in enu[prop]" :key="index" :label="item" :value="Number(index)"></el-option>
              </el-select>
            </template>
            <template v-else>
              <el-input v-model="messageForm[prop]" placeholder="请填写" clearable disabled />
            </template>
          </el-form-item>
        </template>
        <h3>时间信息</h3>
        <template v-for="({prop,label, type = 'date'}) in timeData" :key="prop" >
          <el-form-item :label="label" :prop="prop">
            <template v-if="type === 'input'">
              <el-input v-model="messageForm[prop]" placeholder="请填写" clearable disabled />
            </template>
            <template v-else>
              <el-date-picker
                v-model="messageForm[prop]"
                type="date"
                value-format="YYYY-MM-DD"
                @change="getDivisionAge()"
                placeholder="请选择"
                disabled/>
            </template>
          </el-form-item>
        </template>
        <h3>卡信息</h3>
        <el-form-item  v-for="({prop,label}) in cardData" :key="prop" :label=label :prop=prop >
          <el-input v-model="messageForm[prop]" placeholder="请填写" disabled />
        </el-form-item>
        <h3>年假/病假/加班调休</h3>
        <template v-for="({prop,label, type = 'input'}) in annualData" :key="prop" >
          <el-form-item :label="label" :prop="prop">
            <template v-if="type === 'select'">
              <el-select v-model="messageForm[prop]" filterable :prop="prop" placeholder="请选择" disabled>
                <el-option v-for="(item,index) in enu[prop]" :key="index" :label="item" :value="Number(index)"></el-option>
              </el-select>
            </template>
            <template v-else>
              <el-input v-model="messageForm[prop]" placeholder="请填写" clearable disabled />
            </template>
          </el-form-item>
        </template>

      <br>
      <template v-for="({prop,label}) in overtimeData" :key="prop" >
        <el-form-item  :label=label :prop=prop
          :class="{'overtime-item': prop !== 'overtime_used', 'overtime-item-first': prop === 'overtime_cur_year' }">
          <el-input v-model="messageForm[prop]" placeholder="请填写" disabled >
            <template #suffix>
            <span>h</span>
            </template>
          </el-input>
        </el-form-item>
      </template>
        <h3>户籍信息</h3>
        <template v-for="({prop,label, type = 'input'}) in nativeData" :key="prop" >
          <el-form-item :label="label" :prop="prop">
            <template v-if="type === 'date'">
              <el-date-picker
                v-model="messageForm[prop]"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="请选择"
                disabled/>
            </template>
            <template v-else>
              <el-input v-model="messageForm[prop]" placeholder="请填写" clearable disabled />
            </template>
          </el-form-item>
        </template>
        <h3>个人信息</h3>
        <el-form-item label="毕业学校" prop="graduation_school" >
          <el-input v-model="messageForm.graduation_school" maxlength="20" placeholder="请填写" disabled />
        </el-form-item>
        <el-form-item label="专业" prop="major" >
          <el-input v-model="messageForm.major" maxlength="20" placeholder="请填写" disabled />
        </el-form-item>
        <el-form-item label="毕业时间" prop="graduation_time">
          <el-date-picker
            v-model="messageForm.graduation_time"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="请选择"
            disabled/>
        </el-form-item>
        <el-form-item label="最高学历" prop="highest_degree" >
          <el-input v-model="messageForm.highest_degree" placeholder="请填写" disabled />
        </el-form-item>
        <el-form-item label="开始工作时间" prop="start_work_time">
          <el-date-picker
            v-model="messageForm.start_work_time"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="请选择"
            disabled/>
        </el-form-item>
        <el-form-item label="工龄（年）" prop="seniority" >
            <el-input v-model="messageForm.seniority" placeholder="请填写" disabled />
        </el-form-item>
        <el-form-item label="婚否" prop="married" >
          <el-select v-model="messageForm.married" filterable prop="married" placeholder="请选择" :disabled="is_disabled" clearable>
            <el-option v-for="(item,index) in enu.married" :key="index" :label="item" :value="Number(index)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生育情况" prop="fertility_status" >
          <el-input v-model="messageForm.fertility_status" :disabled="is_disabled" maxlength="20" placeholder="请填写" clearable />
        </el-form-item>

        <h3>地址/联系</h3>
        <el-form-item label="身份证地址" prop="id_card_addr" class="card-present" >
          <el-input v-model="messageForm.id_card_addr" maxlength="40" placeholder="请填写" disabled/>
        </el-form-item>
        <el-form-item label="现在住址" prop="present_addr" class="card-present" >
          <el-input v-model="messageForm.present_addr" maxlength="40" placeholder="请填写" :disabled="is_disabled" clearable />
        </el-form-item>
        <el-form-item label="个人邮箱" prop="email" >
          <el-input v-model="messageForm.email" maxlength="40" placeholder="请填写" disabled />
        </el-form-item>
        <el-form-item label="紧急联系人1" prop="contact1" >
          <el-input v-model="messageForm.contact1" maxlength="20" placeholder="请填写" :disabled="is_disabled" clearable />
        </el-form-item>
        <el-form-item label="手机号码1" prop="mobile1" >
          <el-input v-model="messageForm.mobile1" maxlength="11" placeholder="请填写" clearable :disabled="!messageForm.contact1 || is_disabled" />
        </el-form-item>
        <el-form-item label="紧急联系人2" prop="contact2">
          <el-input v-model="messageForm.contact2" maxlength="20" placeholder="请填写" :disabled="is_disabled" clearable />
        </el-form-item>
        <el-form-item label="手机号码2" prop="mobile2" >
          <el-input v-model="messageForm.mobile2" maxlength="11" placeholder="请填写" clearable :disabled="!messageForm.contact2 || is_disabled" />
        </el-form-item>

        <h3>麦风成长经历</h3>
        <el-table :data="experienceData" border ref="experienceTable" style="width: 95%; margin-bottom: 70px">
          <el-table-column v-for="item in recordTableList" :key="item.key" align="center" :prop="item.key" :label="item.value" :resizable="false"  :width="item.width ? item.width : 'auto'"/>
        </el-table>
      </el-form>
      <el-row class="center-btn" v-if="!is_disabled">
        <el-button @click="$router.go(-1)" class="goback">返回</el-button>
        <el-button type="primary" @click="handleSave">保存</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { reactive, toRefs, onMounted, watch, computed, nextTick } from 'vue'
import { useStore } from 'vuex'
import myRouter from '@/router'
import { getTeamList } from '@/apis/journal.js'
import { getEmployeesInfo, getEmployeesOptions, updateEmployees, exportEmployees } from '@/apis/employees.js'
import { dialogMsg } from '@/utils/dialogMsg.js'
import AlignmentConetentItem from '@/views/layout/work/okr/component/alignment/AlignmenuItem.vue'
import { basicData, deptData, jobData, timeData, cardData, nativeData, annualData, overtimeData, exportExcel } from '@/utils/export.js'

export default {
  name: 'employeesList',
  components: {
    AlignmentConetentItem
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const checkNumber = (rule, value, callback) => {
      const reg = /^[0-9]*$/g
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入数字'))
      }
    }
    const state = reactive({
      id: computed(() => store.state.userInfo.userInfos.id),
      messageRef: null,
      messageForm: {},
      enu: {},
      teamData: {},
      okrTeamCatalogueRef: null,
      is_lead: false,
      is_dept: false,
      experienceData: [],
      recordTableList: [
        { key: 'dept_name', value: '部门' },
        { key: 'position', value: '职位' },
        { key: 'rank_post', value: '职级' },
        { key: 'date_range', value: '变动时间' }
      ],
      ding_user_id: store.state.userInfo.userInfos.user_id,
      is_disabled: false,
      rules: {
        married: [{ required: true, message: '请选择婚否', trigger: 'blur' }],
        fertility_status: [{ required: true, message: '请输入生育情况', trigger: 'blur' }],
        present_addr: [{ required: true, message: '请输入现住地址', trigger: 'blur' }],
        contact1: [{ required: true, message: '请输入紧急联系人1', trigger: 'blur' }],
        mobile1: [{ required: true, message: '请输入手机号码1', trigger: 'blur' }, { validator: checkNumber, trigger: ['blur', 'change'] }],
        mobile2: [{ validator: checkNumber, trigger: ['blur', 'change'] }]
      },
      isCheckAll: false,
      deptItemRef: [],
      isIndeterminate: false
    })

    watch(() => state.isCheckAll, (newValue) => {
      // 勾选状态同步到子组件
      state.deptItemRef.forEach(ref => { ref.isCheckAll = newValue })
      state.teamData.user.forEach(user => { user.checked = newValue })
      changeCheck()
    })

    watch(() => [state.messageForm.contact1, state.messageForm.contact2], (newValue) => {
      newValue.forEach((item, index) => {
        if (!item) {
          state.messageRef.clearValidate([`mobile${index + 1}`])
          state.messageForm[`mobile${index + 1}`] = ''
        }
      })
    })

    onMounted(() => {
      // 获取我的团队
      getMyTeam()
      // 获取个人档案信息
      getEmpInfo({ ding_user_id: 0 })
    })

    // 获取我的团队信息
    const getMyTeam = async () => {
      const data = { type: 1 }
      const { code, data: teamData } = await getTeamList(data)
      if (code === 0) {
        state.teamData = teamData
        state.teamData.children = state.teamData.children.filter(item => item.is_leader === 1)
        if (state.teamData.is_leader || state.teamData.children.length > 0) state.is_lead = true
      }
    }

    // 获取用户信息
    const getEmpInfo = (params) => {
      getEmployeesInfo(params).then(res => {
        if (res.code === 0) {
          state.messageForm = res.data
          state.experienceData = state.messageForm.growth_record
          if (res.data?.dept) state.messageForm.dept = Object.values(res.data?.dept).join(';')
          if (state.messageForm.emergency_contact) {
            state.messageForm.contact1 = state.messageForm.emergency_contact[0].contact || ''
            state.messageForm.mobile1 = state.messageForm.emergency_contact[0].mobile || ''
            state.messageForm.contact2 = state.messageForm.emergency_contact[1].contact || ''
            state.messageForm.mobile2 = state.messageForm.emergency_contact[1].mobile || ''
          }
        } else {
          dialogMsg('error', res.msg)
        }
      })
    }

    // 选择用户得到用户信息
    const clickItem = async (item, status = true) => {
      if (item.ding_user_id) {
        item.ding_user_id === state.ding_user_id ? state.is_disabled = false : state.is_disabled = true
        const params = {
          ding_user_id: item?.ding_user_id
        }
        getEmpInfo(params)
      }
    }
    const methods = {
      selectItem (index, item, routeResult) {
        console.log(index, item, routeResult)
      },
      selectMenu ({ item, status }) {
        clickItem(item, status)
      },
      getDept () {
        state.is_dept = true
      },
      getPersonal () {
        state.is_disabled = false
        getEmpInfo({ ding_user_id: 0 })
      },
      // 保存
      handleSave () {
        state.messageRef.validate((vaild) => {
          if (vaild) {
            if (state.messageForm.contact2 && !state.messageForm.mobile2) {
              state.messageForm.mobile2 = null
              return false
            }
            state.messageForm.emergency_contact = [
              { contact: state.messageForm.contact1, mobile: state.messageForm.mobile1 },
              { contact: state.messageForm.contact2, mobile: state.messageForm.mobile2 }
            ]
            const params = {
              married: state.messageForm.married,
              fertility_status: state.messageForm.fertility_status,
              present_addr: state.messageForm.present_addr,
              emergency_contact: state.messageForm.emergency_contact
            }
            updateEmployees(params).then(res => {
              if (res.code === 0) {
                myRouter.push({ name: 'personalCenter', query: { id: state.id } })
                dialogMsg('success', res.msg)
              } else {
                dialogMsg('error', res.msg)
              }
            }).catch(err => dialogMsg('error', err))
          }
        })
      }
    }

    const requestList = [getEmployeesOptions()]
    Promise.all(requestList).then(res => {
      if (res.every(item => item.code === 0)) {
        const [optionsList] = res.map(item => item.data)
        state.enu = optionsList
      }
    })

    // 子组件修改选项
    const changeCheck = () => {
      nextTick(() => {
        const checkedUserLength = state.teamData.user.filter(item => item.checked).length
        const checkedDeptLength = state.deptItemRef.filter(ref => ref.isCheckAll).length
        const isIndeterminateDeptLength = state.deptItemRef.filter(ref => ref.isIndeterminate).length
        const userLength = state.teamData.user.length
        const deptLength = state.deptItemRef.length
        state.isIndeterminate = (checkedUserLength > 0 && checkedUserLength <= userLength) || (checkedDeptLength > 0 && checkedDeptLength <= deptLength) || isIndeterminateDeptLength > 0
        if (checkedUserLength === 0 && checkedDeptLength === 0) {
          state.isCheckAll = false
        } else if (checkedUserLength === userLength && checkedDeptLength === deptLength && isIndeterminateDeptLength === 0) {
          state.isCheckAll = true
          state.isIndeterminate = false
        }
      })
    }

    const exportData = () => {
      const params = {
        scope_user_id: getUserId(),
        scope_dept_id: []
      }
      exportEmployees(params).then(({ code, data }) => {
        if (code === 0) {
          exportExcel(data.list, state.enu)
        }
      })
    }

    const getUserId = () => {
      // 当前部门用户
      const userIds = state.teamData.user.filter(item => item.checked).map(item => item.id)
      // 子部门的用户
      state.deptItemRef.forEach(ref => {
        userIds.push(...ref.getCheckedUserIds())
      })
      // 去重返回
      return [...new Set(userIds)]
    }

    return {
      route,
      ...toRefs(state),
      basicData,
      deptData,
      jobData,
      cardData,
      timeData,
      nativeData,
      annualData,
      overtimeData,
      getMyTeam,
      clickItem,
      ...methods,
      changeCheck,
      exportData
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-form) {
    margin: 0 15px 0 20px !important;
}
.employees-content {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .employees-detail {
        width: 78%;
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 6px;
        padding-top: 30px;
        margin-left: 20px;
    }
    .center-btn {
        display: flex;
        justify-content: flex-end;
        margin: 0px 80px 70px 0;
        :deep(.el-button){
            min-height: 30px;
        }
        .goback {
            color: #7972f0;
            border-color: #7972f0 !important;
        }
        .el-button {
            width: 88px;
            height: 30px !important;
            border-radius: 30px;
            padding: 0;
            text-align: center;
            line-height: 28px;
        }
    }
}
h3 {
    margin-bottom: 20px;
}

:deep(.el-form) {
    margin: 0 40px;
}
:deep(.el-form-item__content), :deep(.el-date-editor.el-input), :deep(.el-input-number .el-input) {
    width: 151px;
}
// 部门下拉框
.team-card {
    width: 210px;
    min-height: 120px;
    height: auto;
    // overflow: hidden;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
    p {
      width: 180px;
      height: 45px;
    //   color: rgb(96, 98, 102);
      background: #fff;
      font-size: 14px;
      cursor: pointer;
      padding-left: 16px;
      line-height: 45px;
    }
    .line {
      position: relative;
    }
    .line::after {
      content: '';
      display: block;
      width: 170px;
      height: 1px;
      background-color: #F1F1F1;
    }
    :deep(.el-menu-item) {
        height: 40px;
        line-height: 40px;
    }
    :deep(.el-menu),:deep(.el-submenu__title), :deep(.el-menu-item) {
        color: #333 !important;
        border-right: none;
        width: 100%;
    }
    :deep(.el-submenu__title:hover), :deep(.el-menu-item:hover) {
        background: #F2F1FE !important;
        border-radius: 61px;
    }
    :deep(.el-submenu__icon-arrow) {
        margin-top: -3px;
    }
    :deep(.el-form-item__label) {
        color: #333333;
    }
    .input {
        position: relative;
        margin-right: 16px;
        display: flex;
        align-items: center;
            .el-input__inner {
            padding-right: 35px;
            height: 33px;
            border-radius: 32px 0 0 32px;
            &:hover {
                border-color: #999;
            }
            &:focus {
                border-color: @active-text-color;
            }
        }

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 33px;
            border-radius: 0 32px 32px 0;
            cursor: pointer;
            background: @active-text-color;
        }
    }

    .journal-list-left {
        margin-top: 19px;
    }
    .el-checkbox{
      margin-right: 5px;
    }
    .export-btn{
      background: @active-text-color;
      margin: 10px 62px;
      border-radius: 30px;
      padding: 0 30px;
    }
}
.scroll-bar {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
      width: 5px;
      height: 6px;
  }
  &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
      background: #e0e0e0;
      border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8;
  }
}
:deep(.el-button--primary) {
    border-radius: 0;
}
.card-present :deep(.el-form-item__content), .dept-width :deep(.el-form-item__content) {
    width: 670px;
}
:deep(.el-input.is-disabled .el-input__inner) {
    border-color: #F1F1F1;
    background-color: #F6F9FA;
}
:deep(.el-table th>.cell) {
    color: #7972F0;
}
:deep(.el-table th) {
    background: #F6F9FA;
}
.is-opened {
    color: #7972F0;
}

.overtime-item{
  &.overtime-item-first{
    :deep(.el-form-item__label){
      text-indent: 2em;
    }
  }
  :deep(.el-form-item__label){
    line-height: 20px;
  }
}
</style>
